<template>
  <div class="sound">
    <div class="container">
      <!-- 主容器，分左右，右边长列表 -->
      <div class="i-con">
        <!-- 左侧长内容 -->
        <div class="i-l">
          <!-- 头部 -->
          <div class="s-header">
            <h2>2022金瑞营销奖获奖名单揭晓！映宇宙荣获“年度最佳事件营销案例”奖</h2>
            <span> 2023-01-12 12:28:06</span>
            <p>
              1月11日，2022年第十七届金瑞营销奖获奖名单正式揭晓。映宇宙集团凭借2021年6月份的“破壁而来——映客品牌升级发布会”项目荣获“年度最佳事件营销案例”奖！
            </p>
          </div>

          <!-- 内容 -->
          <div class="s-con">
            <!-- 图片 -->
            <img :src="this.img" alt="" />
            <!-- 文字 -->
            <div class="s-text">
              <p>金瑞营销奖iResearch Marketing Awards自创办以来，始终秉承“为商业决策赋能”初心，为挖掘营销价值，创造营销新生力贡献力量。

本届金瑞营销奖凝聚了艾瑞咨询二十年专业力量，汇聚了300余家品牌及营销公司，共征集500余份作品，邀请业内营销专家及领袖人物，层层甄选，入围案例近200个，最终获奖作品80余个。

接下来让我们一同见证荣耀时刻，揭幕荣获第十七届金瑞营销奖的获奖作品和企业，向为营销力量提供源源不断新生力的企业及营销专家致敬！</p>
              <p>{{ this.my_p2 }}</p>
              <p>{{ this.my_p3 }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 获取 id
      myId: this.$route.query.type,
      // 标题
      my_title: "",
      // 段落一
      my_p1: "",
      // 段落2
      my_p2: "",
      my_p3: "",
      author: "",
      time: "",
    };
  },
  mounted() {
    this.init();
    document.title = "北京忠保医学教育科技有限公司";
  },
  methods: {
    init() {
      this.axios.get("/mock/ac1.json").then((res) => {
        var vl = res.data;
        var date = vl.find((vl) => vl.id == this.myId);
        console.log(date);
        // console.log(date)
        // 标题
        this.my_title = date.title;
        // 作者
        this.author = date.author;
        // 歌词
        this.concise = date.concise;
        // 图片路径
        this.img = date.img;
        // 文章 1
        this.my_p1 = date.article1;
        // 文章 2
        this.my_p2 = date.article2;
        this.my_p3 = date.p3;
        this.time = date.time;
        this.sub = date.sub;
        this.ac3 = vl.slice(1, 7);
      });
    },
    // 跳转功能
    goDays(methodsWords, id) {
      this[methodsWords](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
      location.reload();
    },
    // 收费逻辑
    gopay() {
      // console.log('1')

      if (this.state == 0) {
        // this.$router.push("/pay");
        this.showModal = true;
        console.log(this.showModal);
      }
      // 未登录
      if (this.state !== 0) {
        alert("资讯每条3元，点击确定注册购买");
        this.$router.push({ path: "/register", query: { k: 1 } });
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.sound {
  padding-top: 60px;
  box-sizing: border-box;
  .container {
    .banner {
      width: 100%;
      height: 154px;
      margin: 30px 0;
      margin-bottom: 70px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    // 主容器1
    .i-con {
      display: flex;
      justify-content: center;
      width: 100%;
      // height: 3000px;
      // background-color: pink;
      // margin: 160px auto 60px;
      // 左侧
      .i-l {
        padding: 20px;
        width: 70%;
        height: 100%;
        background-color: #fff;
        box-sizing: border-box;

        // 头部
        .s-header {
          width: 100%;
          height: 100px;
          background-color: #fff;
          text-align: center;
          h2 {
            margin-bottom: 30px;
          }
          span {
            font-size: 14px;
            margin: 0 15px;
            i {
              font-weight: 400;
              margin-right: 5px;
            }
          }
        }
        // 文章容器
        .s-con {
          // 图片
          img {
            width: 100%;
            height: 400px;
            margin: 30px auto;
            // background-color: pink;
          }
          // 文章
          .s-text {
            width: 100%;
            text-align: left;
            padding: 20px;
            box-sizing: border-box;
            p {
              margin: 20px auto;
              font-size: 14px;
              line-height: 70px;
            }
          }
        }
      }

      // 右侧
      .i-r {
        width: 30%;
        height: 100%;
        // background-color: #c60023;
        box-sizing: border-box;
        margin-right: 30px;

        // 推荐区块
        .z1-con {
          width: 100%;
          height: 190px;
          background-color: #fff;
          padding-left: 10px;
          box-sizing: border-box;
          span {
            border: none;
            border-radius: 0;
            color: #fff;
            display: inline-block;
            font-size: 14px;
            padding: 5px 15px;
            width: auto;
            margin: -1px 0 0 15px;
            z-index: 2;
            background-color: #00bfb0;
          }
          // 标题
          .z1-title {
            font-size: 24px;
            font-weight: 400;
            margin-bottom: 10px;
            color: #00bfb0;
            margin-top: 20px;
            font-weight: bold;
          }
          // 简介
          .z1-brief {
            font-size: 14px;
            line-height: 1.7;
            color: #999;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            overflow: hidden;
          }
        }

        // 最新文章区块
        .z2-con {
          background-color: #fff;
          border: 1px solid #eaeaea;
          // margin-bottom: 12px;
          padding: 15px;
          line-height: 1.4;
          margin: 20px auto;
          // 标题
          .z2-title {
            background-color: #fbfbfb;
            border-bottom: 1px solid #eaeaea;
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            color: #333;
            font-size: 14px;
            position: relative;
            padding: 9px 15px;
            position: relative;
            top: -15px;
            left: -15px;
            width: 100%;
            font-weight: bold;
            display: flex;
            justify-content: space-between;
            cursor: pointer;
          }

          // 循环项
          .z2-item {
            display: flex;
            margin: 15px auto;
            align-items: center;
            // 图片
            img {
              width: 80px;
              height: 80px;
              background-color: #c60023;
              margin-right: 15px;
            }
            // info
            .z2-info {
              width: 70%;
              .z2-t {
                font-size: 14px;
                color: #333;
                font-weight: 400;
              }
              .z2-time {
                font-size: 13px;
                margin-top: 4px;
                color: #999;
              }
            }
          }
        }
      }
    }
  }
}
</style>

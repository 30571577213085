<template>
  <div>
    <!-- 导航栏 -->
    <!-- <nav-header :href="this.href"></nav-header> -->

    <div class="container">
      <div class="article-main" style="width:1200px;">
        <div class="am-hd" style="margin: 25px 0;">
          <h2>隐私协议</h2>

        </div>
        <div class="am-bd">
          <div class="article-words">
            <p><br></p>
            <p><br></p>
            <p>1、网所有内容，凡注明“来源：“广州创幻数码科技有限公司”的所有文字、图片和音视频资料，版权均属广州创幻数码科技有限公司所有，相关授权合作平台均享有转载和引用的权力。</p>
            <p><br></p>
            <p>除此之外任何媒体、网站或个人未经本站协议授权不得转载、链接、转贴或以其他方式复制发布/发表。已经本网协议授权的媒体、网站，在下载使用时必须注明“稿件来源：“广州创幻数码科技有限公司”，违者本网将依法追究责任。
            </p>
            <p><br></p>
            <p>凡本网注明“来源：广州创幻数码科技有限公司”的文/图等稿件，本网转载出于传递更多信息之目的，并不意味着赞同其观点或证实其内容的真实性。</p>
            <p><br></p>
            <p><br></p>
            <p>2、除注明“来源：广州创幻数码科技有限公司”的内容外，本网以下内容亦不可任意转载：</p>
            <p><br></p>
            <p>a、本网所指向的非本网内容的相关链接内容；</p>
            <p><br></p>
            <p>b、已作出不得转载或未经许可不得转载声明的内容；</p>
            <p><br></p>
            <p>c、未由本网署名或本网引用、转载的他人作品等非本网版权内容；</p>
            <p><br></p>
            <p>d、本网中特有的图形、标志、页面风格、编排方式、程序等；</p>
            <p><br></p>
            <p><br></p>
            <p>e、本网中必须具有特别授权或具有注册用户资格方可知晓的内容；</p>
            <p><br></p>
            <p>f、其他法律不允许或本网认为不适合转载的内容。</p>
            <p><br></p>
            <p>
              3、本站部分频道所载文章、观点、图片、视频、数据等内容以及相关文章评论纯属作者个人观点和网友自行上传，并不代表本站立场，如发现有违法信息或侵犯您的版权、著作权、肖像权行为，请留言或直接与本站管理员联系，我们将在收到您的信息后24小时内作出删除处理！
            </p>
            <p><br></p>
            <p>4、转载或引用本网内容必须是以资料性公共免费信息为使用目的的合理、善意引用，不得对本网内容原意进行曲解、修改，同时必须保留本网注明的“稿件来源”，并自负版权等法律责任。</p>
            <p><br></p>
            <p>5、转载或引用本网内容不得进行如下活动：</p>
            <p><br></p>
            <p>a、损害本网或他人利益；</p>
            <p><br></p>
            <p>b、任何违法行为；</p>
            <p><br></p>
            <p>c、任何可能破坏公秩良俗的行为；</p>
            <p><br></p>
            <p>d、擅自同意他人继续转载、引用本网内容；</p>
            <p><br></p>
            <p>6、转载或引用本网版权所有之内容须注明“转自（或引自）广州创幻数码科技有限公司”字样，并标明本网网址</p>
            <p>7、由本站用户在本站发表的文章，其作者享有版权，并对版权负责；同时本站根据需要，享有在网站和不限于网站场合的使用权力。转载或引用本网中的署名文章，需经原作者同意，并按规定向作者支付稿酬。</p>
            <p><br></p>
            <p>8、对于不当转载或引用本网内容而引起的民事纷争、行政处理或其他损失，本网不承担责任。</p>
            <p><br></p>
            <p><br></p>
            <p>9、如本网转载稿件涉及版权等问题，请作者在一个月内速来电来函与网站编辑部联系，并提供相应证明材料</p>
            <p><br></p>
            <p>10、对不遵守本声明或其他违法、恶意使用本网内容者，本网保留追究其法律责任的权利。</p>
            <p><br></p>
            <p>
              本站（以下亦称“我们”）深知个人信息对您的重要性，我们尊重并保护所有使用我们平台服务的用户的个人信息，并会尽全力保护您的个人信本站（以下亦称“我们”）深知个人信息对您的重要性，我们尊重并保护所有使用我们平台服务的用户的个人信息，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。请在使用我们的产品（或服务）前，仔细阅读并了解本《我们隐私政策》（下称“本隐私政策”）。
            </p>
            <p><br></p>
            <p>一、我们处理个人信息的法律依据</p>
            <p><br></p>
            <p>
              本隐私政策制定的法律依据为《中华人民共和国消费者权益保护法》、《中华人民共和国网络安全法》、《中华人民共和国电子商务法》、《信息安全技术个人信息安本隐私政策制定的法律依据为《中华人民共和国消费者权益保护法》、《中华人民共和国网络安全法》、《中华人民共和国电子商务法》、《信息安全技术个人信息安全规范》以及其他涉及公民个人信息的相关法律法规。通常，我们会基于本隐私政策提示的功能收集您的个人信息。某些情况下，如果涉及其他信息的收集我们会单独向您出示个人信息保护说明条款。
            </p>
            <p><br></p>
            <p>二、本隐私政策的适用范围</p>
            <p><br></p>
            <p>本隐私政策适用于您使用本平台的产品或服务时使用。</p>
            <p><br></p>
            <p>三、我们如何收集和使用您的个人信息</p>
            <p><br></p>
            <p>个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</p>
            <p><br></p>
            <p>
              个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。本隐私政策涉及到的个人敏感信息我们将加粗并加下划线的方式提示您注意阅读。在您向我们提供任何属于敏感信息的个人信息前，请您清楚考虑该等提供是恰当的并且同意您的个人敏感信息可按本隐私政策所述的目的和方式进行处理。我们会在得到您的同意后收集和使用您的敏感信息以实现与我们业务相关的功能，并允许您对这些敏感信息的收集与使用做出不同意的选择，但是拒绝使用这些信息会影响您使用相关功能。
            </p>
            <p><br></p>
            <p>原则上，我们仅会出于本隐私政策所述的以下目的，收集和使用您的个人信息。我们是服装信息平台，所涉场景较多，如果超过以下目的收集和使用您的个人信息时我们会单独向您提示并征得您的同意。</p>
            <p><br></p>
            <p>（一）帮助您成为我们的注册\登录用户</p>
            <p><br></p>
            <p>您自行注册成为我们的用户</p>
            <p><br></p>
            <p>您在使用我们提供的服务时，首先需要成为我们的注册\登录用户。当您注册我们账户时，您需要向我们提供您准备使用的用户名及您本人的手机号码，我们将通过发送短信验证码的方式来验证您的身份是否有效。</p>
            <p><br></p>
            <p>您可自行创建用户名，用户名的命名及使用应遵守相关法律法规并符合网络道德。用户名中不能含有任何侮辱、威胁、淫秽、谩骂等侵害他人合法权益的文字。用户名将作为您在平台上活动的标识，用以区别平台上其他用户。</p>
            <p><br></p>
            <p>当您注册我们账户时，您需要输入您的电子邮箱地址。当您的账户密码遗失时，可以通过注册电子邮箱发送的链接重置密码。</p>
            <p><br></p>
            <p>
              当您注册我们账户时，您需要输入您的真实姓名及身份证号码，实名认证成功后才可成功注册。根据《中华人民共和国网络安全法》对账号实名制要求，减少谣言、黑客攻击等各种威胁网络安全的事件发生，我们要求您提供真实身份信息，否则，我们有权利拒绝为您提供服务。
            </p>
            <p><br></p>
            <p><br></p>
            <p>您提供的上述信息，将在您使用我们服务期间持续授权我们使用。在您注销账号时，我们将停止使用并删除上述信息。</p>
            <p><br></p>
            <p>（二）客户服务</p>
            <p><br></p>
            <p><br></p>
            <p>当您向我们申诉或进行咨询时，为了方便与您联系或帮助您解决问题，我们可能需要您提供姓名、手机号码、电子邮件信息。如您拒绝提供上述信息，可能部分功能无法使用，同时无法向您及时反馈申诉或咨询结果。</p>
            <p><br></p>
            <p>会员在本平台删除账号或后期不继续使用，注销账号时会员可以联系我们，核对信息确认此账号为本人操作使用后方以进行注销，我司承诺会在3个工作日内清除所有个人信息资料。</p>
            <p><br></p>
            <p><br></p>
            <p>（三）征得授权同意的例外</p>
            <p><br></p>
            <p>根据相关法律法规的规定，在以下情形中，我们可以在不征得您的授权同意的情况下收集、使用一些必要的个人信息：</p>
            <p><br></p>
            <p>1、与公共安全、公共卫生、重大公共利益直接相关的；</p>
            <p><br></p>
            <p>2、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
            <p><br></p>
            <p>3、从合法公开披露的信息中收集到您的个人信息，如从合法的新闻报道、政府信息公开等渠道；</p>
            <p><br></p>
            <p>4、根据您的要求签订和履行合同所必需的；</p>
            <p><br></p>
            <p>5、法律法规规定的其他情形。</p>
            <p><br></p>
            <p>四、我们如何保护您的个人信息</p>
            <p><br></p>
            <p>为保障您的信息安全，我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的信息，使您的信息不会被泄漏、毁损或者丢失，包括但不限于SSL、信息加密存储、数据中心的访问控制。</p>
            <p><br></p>
            <p>1、数据加密：我们对于用户的用户名、手机号、注册邮箱、身份证号进行加密存储，保证用户基本信息不会被恶意获取；</p>
            <p><br></p>
            <p>2、身份鉴别：我们通过校验账号密码或者账号绑定手机验证码，进行用户身份合法性鉴别，防止非经授权的介入；</p>
            <p><br></p>
            <p><br></p>
            <p>
              3、账号保护：您的账户均有安全保护功能，请妥善保管您的账户及密码信息。对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您理解，由于技术的限制以及可能存在的各种恶意手段，即便竭尽所能加强安全措施，在信息网络上也不存在“完善的安全措施”。如因您自己的原因导致账户及密码信息泄露而造成的任何法律后果需由您本人负责。
            </p>
            <p><br></p>
            <p>
              4、互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。
            </p>
            <p><br></p>
            <p>五、我们如何处理未成年人的个人信息</p>
            <p><br></p>
            <p>
              1、我们主要为服装信息平台，我们的服务主要面向成年人（原则上18周岁以上为成年人，16周岁以上且以自己的劳动收入为主要生活来源的我们亦视为成年人）。若您是未成年人，在使用我们的产品和/或服务前，您应在监护人的陪同下阅读本隐私政策，并应确保已征得您的监护人同意后使用我们的服务并向我们提供您的信息。
              我们会根据国家相关法律法规的规定着重保护未成年人的个人信息。</p>
            <p><br></p>
            <p>2、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；</p>
            <p><br></p>
            <p>3、如您的监护人不同意您按照本隐私政策使用我们的服务或向我们提供信息，请您立即终止使用我们的服务并及时通知我们。</p>
            <p><br></p>
            <p>4、对于经父母或法定监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。</p>
            <p><br></p>
            <p>
              5、若您是未成年人的监护人，当您对您所监护的未成年人使用我们的服务或其向我们提供的用户信息有任何疑问时，请您及时与我们联系。我们将根据国家相关法律法规及本隐私政策的规定保护未成年人用户信息的保密性及安全性。如果我们发现自己在未事先获得可证实的父母或法定监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。
            </p>
            <p><br></p>
            <p>六、本隐私政策的更新和通知</p>
            <p><br></p>
            <p>我们的隐私政策可能变更。未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会在本页面上发布对本隐私政策所做的任何变更。</p>
            <p><br></p>
            <p>对于重大变更，我们还会提供更为显著的通知（包括对于某些服务， 我们会通过电子邮件发送通知，说明隐私政策的具体变更内容）。</p>
            <p><br></p>
            <p>七、本隐私政策的更新和通知</p>
            <p><br></p>
            <p>我们的隐私政策可能变更。未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会在本页面上发布对本隐私政策所做的任何变更。</p>
            <p><br></p>
            <p>对于重大变更，我们还会提供更为显著的通知（包括对于某些服务， 我们会通过电子邮件发送通知，说明隐私政策的具体变更内容）。</p>
            <p><br></p>
            <p>本隐私政策所指的重大变更包括但不限于：</p>
            <p><br></p>
            <p><br></p>
            <p>1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</p>
            <p><br></p>
            <p>2、我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</p>
            <p><br></p>
            <p>3、个人信息共享、转让或公开披露的主要对象发生变化；</p>
            <p><br></p>
            <p>4、您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
            <p><br></p>
            <p><br></p>
            <p>5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</p>
            <p><br></p>
            <p><br></p>
            <p>4、免责声明</p>
            <p><br></p>
            <p>1. 通过我们平台，您可以按照我们的规则发布各种商品及其他生活服务信息。但所发布之信息不得含有如下内容：</p>
            <p><br></p>
            <p>（1） 反对宪法所确定的基本原则，煽动抗拒、破坏宪法和法律、行政法规实施的；</p>
            <p><br></p>
            <p>（2）煽动危害国家安全、泄露国家秘密、颠覆国家政权，推翻社会主义制度的；</p>
            <p><br></p>
            <p><br></p>
            <p>（3）煽动分裂国家、破坏国家统一、损害国家荣誉和民族利益的；</p>
            <p><br></p>
            <p>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
            <p><br></p>
            <p>（5）捏造或者歪曲事实，散布谣言，扰乱社会秩序的；</p>
            <p><br></p>
            <p>（6）进行政治宣传或破坏国家宗教政策、宣扬封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖、教唆犯罪的；</p>
            <p><br></p>
            <p>（7）公然侮辱他人或者捏造事实诽谤他人的，或者进行其他恶意攻击的；</p>
            <p><br></p>
            <p>（8）损害国家机关信誉的；</p>
            <p><br></p>
            <p>（9）其他违反宪法和法律法规的；</p>
            <p><br></p>
            <p>2. 在接受我们服务的过程中，您不得从事下列行为：</p>
            <p><br></p>
            <p>
              （1）在使用我们平台服务过程中实施的所有行为均遵守国家法律、法规等规范文件及我们平台各项规则的规定和要求，不违背社会公共利益或公共道德，不损害他人的合法权益，不违反本协议及相关规则。您如果违反前述承诺，产生任何法律后果的，您应以自己的名义独立承担所有的法律责任，并确保我们免于因此产生任何损失或增加费用。
            </p>
            <p><br></p>
            <p>（2）不发布国家禁止发布的信息，不发布涉嫌侵犯他人知识产权或其它合法权益的信息，不发布违背社会公共利益或公共道德、公序良俗的信息，不发布其它涉嫌违法或违反本协议及各类规则的信息。</p>
            <p><br></p>
            <p>（3）不对我们平台上的任何数据作商业性利用，包括但不限于在未经我们事先书面同意的情况下，以复制、传播等任何方式使用我们平台上展示的资料。</p>
            <p><br></p>
            <p>（4）不使用任何装置、软件或例行程序干预或试图干预我们平台的正常运作或正在我们平台上进行的任何活动。您不得采取任何将导致不合理的庞大数据负载加诸我们平台网络设备的行动。</p>
            <p><br></p>
            <p>3. 您同意，在发现本网站任何内容不符合法律规定，或不符合本用户协议规定的，您有义务及时通知我们。如果您发现您的个人信息被盗用、您的版权或者其他权利被侵害，请将此情况告知我们并同时提供如下信息和材料：</p>
            <p><br></p>
            <p>（1）侵犯您权利的信息的网址，编号或其他可以找到该信息的细节；</p>
            <p><br></p>
            <p>（2）您是所述的版权或者其他权利的合法拥有者的权利证明；</p>
            <p><br></p>
            <p><br></p>
            <p>（3）您的联系方式，包括联系人用户名，地址，电话号码和电子邮件；</p>
            <p><br></p>
            <p>
              4.本公司网站平台有责任起到平台信息发布的真实性及安全性监管责任，本公司设网站信息监管部门及部门主要负责人，对用户使用本网站的情况及发布的信息进行审查和监督，如用户在使用本网站时违反任何上述规定及其他本站相关协议，本网站有权要求用户改正或直接采取一切必要的措施（包括但不限于更改或删除用户张贴的内容、暂停或终止用户使用本网站的权利），以减轻用户不当行为造成的影响。如发现用户在本平台发布的信息违反任何上述规定及本站相关规则,本站将依法采取相关措施。并且本网站设有投诉、举报渠道，用户可通过平台投递平台不良信息举报信（说明情况及附加不良信息截图举证），本公司承诺将在8小时内进行处理及反馈，若情况属实，本平台将根据实际情况及严重性立即采取相应惩罚及处理措施（警告、不良信息删除、账号封停及账号强制注销等措施）。如出现恶意举报行为，本公司也将根据实际情况对该用户采取相应惩罚及处理措施。未经本公司的同意，任何网站不得与本网站全部或部分网页建立超链接。
            </p>
            <p><br></p>
            <p>5、本平台仅向您提供本平台服务，您了解本平台上的信息系用户自行发布，本公司会对平台信息进行经过初步审核，但由于海量信息的存在，您应谨慎判断确定相关信息的真实性、合法性和有效性，并注意保留相应的证据以利于维权。
            </p>
            <p><br></p>
            <p>
              6、用户将照片、个人信息等资料上传到互联网上，有可能会被其他组织或个人复制、转载、擅改或做其它非法用途，用户必须充分意识此类风险的存在。用户明确同意其使用本平台服务所存在的风险（包括但不限于受到第三方侵权或对第三方造成侵权）完全由其自己承担；因其使用本平台服务而产生的一切后果也由其自己承担，本平台对此不承担任何责任。
            </p>
            <p><br></p>
            <p>
              7、对于因不可抗力（包括但不限于：信息网络正常的设备维护，信息网络连接故障，电脑、通讯或其他系统的故障，电力故障，罢工，劳动争议，暴乱，起义，骚乱，生产力或生产资料不足，火灾，洪水，风暴，爆炸，战争，政府行为，司法行政机关的命令）或本平台不能控制的原因造成的本网站服务中断或其它缺陷，本平台不承担任何责任，但本平台将尽力减少因此而给用户造成的损失和影响。
            </p>
            <p><br></p>
            <p>8、请自行妥善保管您的账号及密码，如您未保管好您的账号及密码而对您、本公司会根据业务需要向您收集相关信息，以便为您提供更优质的服务。同时本公司将会依照国家有关法律法规，采取各种严格的措施确保您的信息安全。
            </p>
            <p><br></p>
            <p>
              9.互联网传输可能会受到干扰，中断、延迟或数据错误，本公司对于非本公司能控制的通讯设施故障可能引致的数据之准确性或及时性不负任何责任。不保证网站服务器及网络的稳定性，不保证本网站在任何时候均可供浏览、阅读和使用。
            </p>
            <p><br style="white-space: normal;"></p>
            <p><br></p>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import NavHeader from "./../components/NavHeader";
export default {
  name: "ag",
  components: {
    // NavHeader,
  },
  data() {
    return {
      href: "index.html",
    };
  },
  methods() {
    document.title = "广州创幻数码科技有限公司";
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.container {}
</style>

<template>
  <div>
    <!-- 导航栏 -->
    <!-- <nav-header :href="this.href"></nav-header> -->

    <div class="container">
      <div class="article-main">
        <div class="am-hd" style="margin: 25px 0;">
          <h2>用户协议</h2>
         
        </div>
        <div class="am-bd">
          <div class="article-words">
            <p>1.
              用户应遵守中华人民共和国相关法律法规，并同意对以任何方式使用用户账号而使用本服务的任何行为及其结果承担全部责任。如用户的行为违反国家法律，用户将依法承担全部法律责任；如涉嫌构成犯罪，司法机关将追究用户的刑事责任，服务协议
            </p>
            <p><br></p>
            <p>欢迎您使用广州创幻数码科技有限公司的服务！</p>
            <p><br></p>
            <p>
              为使用广州创幻数码科技有限公司的服务，您应当阅读并遵守《广州创幻数码科技有限公司使用协议》和《广州创幻数码科技有限公司隐私政策》。本协议是用户与广州创幻数码科技有限公司之间的法律协议，是用户注册广州创幻数码科技有限公司平台账号和/或使用广州创幻数码科技有限公司服务时使用的通用条款。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、管辖与法律适用条款。限制、免责条款可能以黑体加粗或加下划线的形式提示您重点注意。您不应当以广州创幻数码科技有限公司未对本协议以合理方式提醒用户注意或未根据用户要求尽到说明义务为理由而声称或要求法院或其它任何第三方确认相关条款非法或无效。除非您已阅读并接受本协议所有条款，否则您无权使用广州创幻数码科技有限公司提供的服务。您使用广州创幻数码科技有限公司的服务即视为您已阅读并同意上述协议的约束。
            </p>
            <p><br></p>
            <p>一、定义</p>
            <p><br></p>
            <p>
              广州创幻数码科技有限公司平台，是指广州创幻数码科技有限公司有限公司旗下运营之广州创幻数码科技有限公司网站用户，包含注册用户和非注册用户，以下亦称为“您”。注册用户是指通过广州创幻数码科技有限公司平台完成全部注册程序后，使用广州创幻数码科技有限公司平台服务或广州创幻数码科技有限公司网站资料的用户。非注册用户是指未进行注册、直接登录广州创幻数码科技有限公司平台或通过其他网站进入广州创幻数码科技有限公司平台直接或间接地使用广州创幻数码科技有限公司平台服务或广州创幻数码科技有限公司网站资料的用户。
            </p>
            <p><br></p>
            <p>协议方，本协议中协议双方合称“协议方”。广州创幻数码科技有限公司有限公司及其相关服务可能存在的运营关联单位、广州创幻数码科技有限公司平台在协议中统称为"广州创幻数码科技有限公司"。</p>
            <p><br></p>
            <p>二、协议的效力</p>
            <p><br></p>
            <p>1、在您按照注册页面提示填写信息、阅读并同意本协议并完成全部注册程序后或以其他广州创幻数码科技有限公司允许的方式实际使用广州创幻数码科技有限公司平台服务时，您即受本协议的约束。</p>
            <p><br></p>
            <p>
              2、本协议内容包括本协议正文《广州创幻数码科技有限公司隐私政策》，且您在使用广州创幻数码科技有限公司某一特定服务时，该服务可能会另有单独的协议、相关业务规则等（以下统称为“规则”）。所有广州创幻数码科技有限公司已经发布的或将来可能发布的规则为本协议不可分割的组成部分，与本协议具有同等法律效力。除另行明确声明外，任何广州创幻数码科技有限公司及其关联公司提供的服务（以下称为“广州创幻数码科技有限公司平台服务”）均受本协议约束。您承诺接受并遵守本协议的约定。如果您不同意本协议的约定，您应立即停止注册程序或停止使用广州创幻数码科技有限公司平台服务；如您继续访问和使用广州创幻数码科技有限公司平台服务，即视为您已确知并完全同意本协议各项内容。
            </p>
            <p><br></p>
            <p>
              3、广州创幻数码科技有限公司有权根据国家法律法规的更新、产品和服务规则的调整需要不时地制订、修改本协议及/或各类规则，并提前以网站公示的方式进行公示。如您继续使用广州创幻数码科技有限公司平台服务的，即表示您接受经修订的协议和规则。如发生有关争议时，以广州创幻数码科技有限公司最新的相关协议和规则为准。
            </p>
            <p>三、注册</p>
            <p><br></p>
            <p>
              1、您确认，在您完成注册程序或以其他广州创幻数码科技有限公司允许的方式实际使用广州创幻数码科技有限公司平台服务时，您应当是具备相应民事行为能力的自然人（十六周岁以上的未成年人，以自己的劳动收入为主要生活来源的，视为完全民事行为能力人）、法人或其他组织。若您不具备前述主体资格，则您及您的家长或法定监护人（以下统称"监护人"）应承担因此而导致的一切后果，且广州创幻数码科技有限公司有权注销您的账户，并向您及您的监护人索偿。
            </p>
            <p><br></p>
            <p>
              2、广州创幻数码科技有限公司非常重视对青少年个人信息搜集和使用的安全性的保护。广州创幻数码科技有限公司建议，任何未满18周岁的未成年人参加网上活动应事先取得其监护人可经查证的同意并遵守《全国青少年网络文明公约》。监护人应承担未成年人网络活动风险及保护未成年人相关网络隐私的首要责任。
            </p>
            <p><br></p>
            <p>
              3、在您签署本协议，完成注册程序时，广州创幻数码科技有限公司会向您提供唯一编号的广州创幻数码科技有限公司账户。您应对您的用户账户、登录密码、支付密码（如有）、验证码的安全，以及对通过您的账户和密码实施的行为负责；因此所衍生的任何损失或损害，广州创幻数码科技有限公司无法也不承担任何责任。除非有法律规定或行政司法机关的指令，且征得广州创幻数码科技有限公司的同意，否则您的用户账户、登录密码、支付密码（如有）和验证码不得以任何方式转让、借用、赠与、继承（与账户相关的财产权益除外）或&gt;在第三方平台上进行展示或售卖。否则，由此给您（或广州创幻数码科技有限公司、任何第三方）造成的一切损失，概由您自行承担（或者负责赔偿）。
            </p>
            <p><br></p>
            <p>
              4、广州创幻数码科技有限公司承诺非经法定原因、本协议的约定或您的事先许可，广州创幻数码科技有限公司不会向任何第三方透露您的注册账号、手机号码等非公开信息。如果发现任何人不当使用您的账户或有任何其他可能危及您的账户安全的情形时，您应当立即以有效方式通知广州创幻数码科技有限公司，要求广州创幻数码科技有限公司暂停相关服务。您理解广州创幻数码科技有限公司对您的请求采取行动需要合理时间，广州创幻数码科技有限公司对在采取行动前已经产生的后果（包括但不限于您的任何损失）不承担任何责任。
            </p>
            <p><br></p>
            <p>
              5、您在注册帐号或使用广州创幻数码科技有限公司平台服务的过程中，应提供合法、真实、准确的个人资料，您填写的个人资料有变动的，应及时进行更新。如果因您提供的个人资料不合法、不真实、不准确的，您需承担因此引起的相应责任及后果，并且广州创幻数码科技有限公司保留终止您使用广州创幻数码科技有限公司各项服务的权利。
            </p>
            <p><br></p>
            <p>
              6、您了解并同意，如您符合并且遵守本协议条款，在通过广州创幻数码科技有限公司平台完成注册程序之后，即可成为广州创幻数码科技有限公司平台注册用户。对于您主动提交的相关信息，您授权广州创幻数码科技有限公司及/或广州创幻数码科技有限公司网站运营者及关联服务提供方委托的第三方通过合法渠道（包括但不限于征信机构等）了解、咨询、审查您的个人市场交易风险的真实情况，并据此判断您的风险状况。
            </p>
            <p><br></p>
            <p>7、您不得通过任何手段恶意注册广州创幻数码科技有限公司网站帐号，包括但不限于以牟利、炒作、套现等为目的多个账号注册。您亦不得盗用其他用户帐号。</p>
            <p><br></p>
            <p>
              8、您了解并同意，一经注册用户账号，即视为您同意广州创幻数码科技有限公司及/或其关联公司可以通过拨打电话、发送短信或者电子邮件等方式向您注册时填写的手机号码、电子邮箱等发送、告知相应的产品服务广告信息、促销优惠等营销信息；您如果不同意接收相关信息，您可以通过相应的退订功能或相关提示进行退订。
            </p>
            <p><br></p>
            <p>四、广州创幻数码科技有限公司平台服务使用规范</p>
            <p><br></p>
            <p>1、通过广州创幻数码科技有限公司平台，您可以按照广州创幻数码科技有限公司的规则发布各种生活信息。但所发布之信息不得含有如下内容：</p>
            <p>1) 反对宪法所确定的基本原则，煽动抗拒、破坏宪法和法律、行政法规实施的；</p>
            <p>2)煽动危害国家安全、泄露国家秘密、颠覆国家政权，推翻社会主义制度的；</p>
            <p>3)煽动分裂国家、破坏国家统一、损害国家荣誉和民族利益的；</p>
            <p>4)煽动民族仇恨、民族歧视，破坏民族团结的；</p>
            <p>5)捏造或者歪曲事实，散布谣言，扰乱社会秩序的；</p>
            <p>6)进行政治宣传或破坏国家宗教政策、宣扬封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖、教唆犯罪的；</p>
            <p>7)公然侮辱他人或者捏造事实诽谤他人的，或者进行其他恶意攻击的；</p>
            <p>8)损害国家机关信誉的；</p>
            <p>9)其他违反宪法和法律法规的；</p>
            <p><br></p>
            <p><br></p>
            <p>2、在接受广州创幻数码科技有限公司服务的过程中，您不得从事下列行为：</p>
            <p>1）发表、传送、传播、储存侵害他人知识产权、商业秘密权等合法权利的内容，包含病毒、木马、定时炸弹等可能对广州创幻数码科技有限公司系统造成伤害或影响其稳定性的内容制造虚假身份以误导、欺骗他人；</p>
            <p><br></p>
            <p>2）传送或散布以其他方式实现传送的含有受到知识产权法律保护的图像、相片、软件或其他资料的文件，作为举例（但不限于此）：包括版权或商标法（或隐私权或公开权），除非用户拥有或控制着相应的权利或已得到所有必要的认可；
            </p>
            <p><br></p>
            <p>3）使用任何包含有通过侵犯商标、版权、专利、商业机密或任何一方的其他专有权利的方式利用本“软件”获得的图像或相片的资料或信息；</p>
            <p><br></p>
            <p>
              4）进行危害计算机网络安全的行为，包括但不限于：使用未经许可的数据或进入未经许可的服务器/帐号；未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；未经许可，企图探查、扫描、测试本平台系统或网络的弱点或其它实施破坏网络安全的行为；企图干涉、破坏本平台系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；伪造TCP/IP数据包名称或部分名称；
            </p>
            <p><br></p>
            <p>5）修改或伪造软件作品运行中的指令、数据、数据包，增加、删减、变动软件 的功能或运行效果，不得将用于上述用途的软件通过信息网络向公众传播或者运营；</p>
            <p><br></p>
            <p>6）在未经广州创幻数码科技有限公司书面明确授权前提下，出售、出租、出借、散布、转移或转授权软件和服务或相关的链接或从使用软件和服务或软件和服务的条款中获利，无论以上使用是否为直接经济或金钱收益；</p>
            <p><br></p>
            <p><br></p>
            <p>7） 违背广州创幻数码科技有限公司页面公布之活动规则，包括但不限于发布虚假信息、作弊或通过其他手段进行虚假交易。</p>
            <p><br></p>
            <p><br></p>
            <p>8）对广州创幻数码科技有限公司网站进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现广州创幻数码科技有限公司网站的源代码；</p>
            <p><br></p>
            <p>
              9）对广州创幻数码科技有限公司网站或者广州创幻数码科技有限公司网站运行过程中释放到任何终端内存中的数据、网站运行过程中客户端与服务器端的交互数据，以及广州创幻数码科技有限公司网站运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经广州创幻数码科技有限公司授权的第三方工具/服务接入本网站和相关系统；
            </p>
            <p><br></p>
            <p>3、您了解并同意，广州创幻数码科技有限公司有权应政府部门（包括司法及行政部门）的要求，向其提供您在广州创幻数码科技有限公司平台填写的注册信息和发布纪录等必要信息，且无须征得您的同意或提前通知于您。</p>
            <p><br></p>
            <p>4、在广州创幻数码科技有限公司平台上使用广州创幻数码科技有限公司服务过程中，您承诺遵守以下约定：</p>
            <p><br></p>
            <p>
              1)在使用广州创幻数码科技有限公司台服务过程中实施的所有行为均遵守国家法律、法规等规范文件及广州创幻数码科技有限公司平台各项规则的规定和要求，不违背社会公共利益或公共道德，不损害他人的合法权益，不违反本协议及相关规则。您如果违反前述承诺，产生任何法律后果的，您应以自己的名义独立承担所有的法律责任，并确保广州创幻数码科技有限公司免于因此产生任何损失或增加费用。
            </p>
            <p><br></p>
            <p>2)不发布国家禁止发布的信息（除非取得合法且足够的许可），不发布涉嫌侵犯他人知识产权或其它合法权益的信息，不发布违背社会公共利益或公共道德、公序良俗的信息，不发布其它涉嫌违法或违反本协议及各类规则的信息。
            </p>
            <p><br></p>
            <p>3)不对广州创幻数码科技有限公司平台上的任何数据作商业性利用，包括但不限于在未经广州创幻数码科技有限公司事先书面同意的情况下，以复制、传播等任何方式使用广州创幻数码科技有限公司平台站上展示的资料。</p>
            <p><br></p>
            <p>
              4)不使用任何装置、软件或例行程序干预或试图干预广州创幻数码科技有限公司平台的正常运作或正在广州创幻数码科技有限公司平台上进行的任何活动。您不得采取任何将导致不合理的庞大数据负载加诸广州创幻数码科技有限公司平台网络设备的行动。
            </p>
            <p><br></p>
            <p>5、您了解并同意：</p>
            <p><br></p>
            <p>1)您违反上述承诺时，广州创幻数码科技有限公司有权依据本协议的约定，做出相应处理或终止向您提供服务，且无须征得您的同意或提前通知于您。</p>
            <p><br></p>
            <p>
              2)根据相关法令的指定或者广州创幻数码科技有限公司服务规则的判断，您的行为涉嫌违反法律法规的规定或违反本协议和/或规则的条款的，广州创幻数码科技有限公司有权采取相应措施，包括但不限于直接屏蔽、删除侵权信息、降低您的信用值或直接停止提供服务。如因此使广州创幻数码科技有限公司遭受损失，或受到任何第三方的索赔，或受到任何行政管理部门的处罚，您应当赔偿广州创幻数码科技有限公司因此造成的损失及（或）发生的费用，包括合理的律师费用。
            </p>
            <p><br></p>
            <p>
              3)对于您在广州创幻数码科技有限公司平台上实施的行为，包括您未在广州创幻数码科技有限公司平台上实施但已经对广州创幻数码科技有限公司平台及其用户产生影响的行为，广州创幻数码科技有限公司有权单方认定您行为的性质及是否构成对本协议和/或规则的违反，并据此采取相应的处理措施。您应自行保存与您行为有关的全部证据，并应对无法提供充要证据承担不利后果。
            </p>
            <p><br></p>
            <p><br></p>
            <p>4)对于您涉嫌违反承诺的行为对任意第三方造成损害的，您均应当以自己的名义独立承担所有的法律责任，并应确保广州创幻数码科技有限公司免于承担因此产生的损失或增加的费用。</p>
            <p><br></p>
            <p>五、责任范围和责任限制</p>
            <p><br></p>
            <p>
              1、广州创幻数码科技有限公司负责向您提供广州创幻数码科技有限公司平台服务。但广州创幻数码科技有限公司对广州创幻数码科技有限公司平台服务不作任何明示或暗示的保证，包括但不限于广州创幻数码科技有限公司平台服务的适用性、没有错误或疏漏、持续性、准确性、可靠性、适用于某一特定用途。同时，广州创幻数码科技有限公司也不对广州创幻数码科技有限公司平台服务所涉及的技术及信息的有效性、准确性、正确性、可靠性、稳定性、完整性和及时性作出任何承诺和保证。
            </p>
            <p><br></p>
            <p>
              2、广州创幻数码科技有限公司仅向您提供广州创幻数码科技有限公司平台服务，您了解广州创幻数码科技有限公司平台上的信息系用户自行发布，由于海量信息的存在，且广州创幻数码科技有限公司平台无法杜绝可能存在风险和瑕疵。您应谨慎判断确定相关信息的真实性、合法性和有效性，并注意保留相应的证据以利于维权，如可能，尽量采用网站建议的交易方式进行。
            </p>
            <p><br></p>
            <p>3、广州创幻数码科技有限公司平台与其他的在线使用的互联网网站一样，也会受到各种不良信息、网络安全和网络故障问题的困扰，包括但不限于：</p>
            <p><br></p>
            <p>1）其他用户可能会发布诈骗或虚假信息，或者发表有谩骂、诅咒、诋毁、攻击内容的，或者含有淫秽、色情、下流、反动、煽动民族仇恨等让人反感、厌恶的内容的非法言论；</p>
            <p><br></p>
            <p>2）其他用户可能会发布一些侵犯您或者其他第三方知识产权、肖像权、姓名权、名誉权、隐私权和/或其他合法权益的图片、照片、文字等资料；</p>
            <p><br></p>
            <p>3）面临着诸如黑客攻击、计算机病毒困扰、系统崩溃、网络掉线、网速缓慢、程序漏洞等问题的困扰和威胁。</p>
            <p><br></p>
            <p>
              您充分了解并同意：上述的各种不良信息、网络安全和网络故障问题，并不是广州创幻数码科技有限公司或者广州创幻数码科技有限公司平台所导致的问题，由此可能会造成您感到反感、恶心、呕吐等精神损害，或者给您造成其他的损失，概由您自行承担，广州创幻数码科技有限公司无须向您承担任何责任。
            </p>
            <p><br></p>
            <p>
              4、您同意，在发现本网站任何内容不符合法律规定，或不符合本用户协议规定的，您有义务及时通知广州创幻数码科技有限公司。如果您发现您的个人信息被盗用、您的版权或者其他权利被侵害，请将此情况告知广州创幻数码科技有限公司并同时提供如下信息和材料：
            </p>
            <p><br></p>
            <p>1)侵犯您权利的信息的网址，编号或其他可以找到该信息的细节；</p>
            <p><br></p>
            <p>2)您是所述的版权或者其他权利的合法拥有者的权利证明；</p>
            <p><br></p>
            <p>3)您的联系方式，包括联系人姓名，地址，电话号码和电子邮件；</p>
            <p><br></p>
            <p>4)您的身份证复印件、营业执照等其他相关资料。</p>
            <p><br></p>
            <p><br></p>
            <p>经审查得到证实的，我们将及时删除相关信息。我们仅接受邮寄、电子邮件或传真方式的书面侵权通知。情况紧急的，您可以通过客服电话先行告知，我们会视情况采取相应措施。</p>
            <p><br></p>
            <p>5、您了解并同意，广州创幻数码科技有限公司不对因下述任一情况而导致您的任何损害赔偿承担责任，包括但不限于利润、商誉、使用、数据等方面的损失或其它无形损失的损害赔偿：</p>
            <p><br></p>
            <p>1) 使用或未能使用广州创幻数码科技有限公司平台服务；</p>
            <p><br></p>
            <p>2) 第三方未经批准地使用您的账户或更改您的数据；</p>
            <p><br></p>
            <p>3) 通过广州创幻数码科技有限公司平台服务购买或获取任何商品、样品、数据、信息等行为或替代行为产生的费用及损失；</p>
            <p><br></p>
            <p>4) 您对广州创幻数码科技有限公司平台服务的误解；</p>
            <p><br></p>
            <p>5) 任何非因广州创幻数码科技有限公司的原因而引起的与广州创幻数码科技有限公司平台服务有关的其它损失。</p>
            <p><br></p>
            <p>6、您在广州创幻数码科技有限公司上使用第三方提供的产品或服务时，除遵守本协议约定外，还应遵守第三方的用户协议。广州创幻数码科技有限公司和第三方对可能出现的纠纷在法律规定和约定的范围内各自承担责任。</p>
            <p><br></p>
            <p>
              7、您同意在使用广州创幻数码科技有限公司平台服务过程中显示广州创幻数码科技有限公司自行或由第三方服务商向您发送的推广或宣传信息（包括商业与非商业信息），其方式和范围可不经向您特别通知而变更。除法律法规明确规定外，您应自行对依该推广信息进行的交易负责，对用户因依该推广信息进行的交易或前述第三方服务商提供的内容而遭受的损失或损害，广州创幻数码科技有限公司不承担任何责任。
            </p>
            <p><br></p>
            <p>
              8、广州创幻数码科技有限公司对下列不可抗力行为免责：信息网络正常的设备维护，信息网络连接故障，电脑、通讯或其他系统的故障，电力故障，罢工，劳动争议，暴乱，起义，骚乱，生产力或生产资料不足，火灾，洪水，风暴，爆炸，战争，政府行为，司法行政机关的命令或第三方的不作为而造成的不能服务或延迟服务。
            </p>
            <p><br></p>
            <p>9、您应当严格遵守本协议及广州创幻数码科技有限公司发布的其他协议、活动规则，因您违反协议或规则的行为给第三方或广州创幻数码科技有限公司造成损失的，您应当承担全部责任。</p>
            <p><br></p>
            <p>10、广州创幻数码科技有限公司保留在中华人民共和国大陆地区法施行之法律允许的范围内独立决定拒绝服务、关闭用户账户、清除或编辑内容或取消订单的权利。</p>
            <p><br></p>
            <p>六、协议终止</p>
            <p><br></p>
            <p>
              1、您同意，广州创幻数码科技有限公司基于平台服务的安全性，有权中止向您提供部分或全部广州创幻数码科技有限公司平台服务，暂时冻结您的账户，待安全问题解决后及时恢复，并对中止、冻结及恢复的事实及时通知。如果网站的安全问题是由于您的违法行为引起，广州创幻数码科技有限公司有权终止向您提供部分或全部广州创幻数码科技有限公司平台服务，永久冻结（注销）您的帐户，并有权向您对损失进行索赔。
            </p>
            <p><br></p>
            <p>2、如您对本协议的修改有异议，或对广州创幻数码科技有限公司的服务不满，可以行使如下权利：</p>
            <p><br></p>
            <p>1）停止使用广州创幻数码科技有限公司的网络服务；</p>
            <p><br></p>
            <p>
              2）通过客服等渠道告知广州创幻数码科技有限公司停止对其服务。结束服务后，您使用广州创幻数码科技有限公司服务的权利立即终止。在此情况下，广州创幻数码科技有限公司没有义务传送任何未处理的信息或未完成的服务给您或任何无直接关系的第三方。
            </p>
            <p><br></p>
            <p>3、您同意，您与广州创幻数码科技有限公司的协议关系终止后，广州创幻数码科技有限公司仍享有下列权利：</p>
            <p><br></p>
            <p>1) 继续保存您未及时删除的注册信息及您使用广州创幻数码科技有限公司平台服务期间发布的所有信息至法律规定的记录保存期满。</p>
            <p><br></p>
            <p>2）您在使用广州创幻数码科技有限公司平台服务期间存在违法行为或违反本协议和/或规则的行为的，广州创幻数码科技有限公司仍可依据本协议向您主张权利、追究责任。</p>
            <p><br></p>
            <p>七、隐私权政策</p>
            <p><br></p>
            <p>1、广州创幻数码科技有限公司将在广州创幻数码科技有限公司平台公布并不时修订隐私权条款，隐私权条款构成本协议的有效组成部分。</p>
            <p><br></p>
            <p>2、您知悉并承诺在使用广州创幻数码科技有限公司的服务过程中，遵守《广州创幻数码科技有限公司隐私政策》。</p>
            <p><br></p>
            <p>3、广州创幻数码科技有限公司要求各搜索引擎遵循行业规范，即“拒绝 Robots 访问标准”(Robots Exclusion
              Standard)，否则将视你的抓取行为是对我网站财产权利和知识产权的侵犯，有权通过法律诉讼维护网站利益。</p>
            <p><br></p>
            <p>八、知识产权声明</p>
            <p><br></p>
            <p>1、广州创幻数码科技有限公司所在公司及其关联公司的商标，受法律保护，任何人不得擅自使用。凡侵犯本公司版权等知识产权的，广州创幻数码科技有限公司将依法追究其相关法律责任。</p>
            <p><br></p>
            <p>
              2、广州创幻数码科技有限公司平台所刊登的资料信息（包括但不限于编码、文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件），均是广州创幻数码科技有限公司或其内容提供者的财产，受中国和国际版权法的保护。本平台上所有内容的汇编是广州创幻数码科技有限公司的排他财产，受中国和国际版权法的保护。本平台上所有软件都是广州创幻数码科技有限公司或其关联公司或其软件供应商的财产，受中国和国际版权法的保护。未经广州创幻数码科技有限公司的明确书面许可，任何第三方不得为任何非私人或商业目的获取或使用广州创幻数码科技有限公司网站的任何部分或通过广州创幻数码科技有限公司网站可直接或间接获得的任何内容、服务或资料。任何第三方违反本协议的规定以任何方式，和/或以任何文字对广州创幻数码科技有限公司网站的任何部分进行发表、复制、转载、更改、引用、链接、下载或以其他方式进行使用，或向任何其他第三方提供获取广州创幻数码科技有限公司网站任何内容的渠道，则对广州创幻数码科技有限公司网站的使用权将立即终止，且任何第三方必须按照广州创幻数码科技有限公司的要求，归还或销毁使用广州创幻数码科技有限公司网站任何部分的内容所创建的资料的任何副本。
            </p>
            <p><br></p>
            <p>
              3、用户一旦接受本协议，即表明该用户主动将其在任何时间段在本平台发表的任何形式的信息内容（包括但不限于用户评价、用户咨询、各类话题文章等信息内容）的财产性权利等任何可转让的权利，如著作权财产权（包括并不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可转让权利），全部独家且不可撤销地转让给广州创幻数码科技有限公司所有，用户同意广州创幻数码科技有限公司有权就任何主体侵权而单独提起诉讼。
            </p>
            <p><br></p>
            <p>
              4、本协议已经构成《中华人民共和国著作权法》第二十五条（条文序号依照2010年版著作权法确定）及相关法律规定的著作财产权等权利转让的书面协议，其效力及于用户在广州创幻数码科技有限公司平台上发布的任何受著作权法保护的作品内容，无论该等内容形成于本协议订立前还是本协议订立后。
            </p>
            <p><br></p>
            <p><br></p>
            <p>5、用户同意并已充分了解本协议的条款，承诺不将已发表于本站的信息，以任何形式发布或授权其它主体以任何方式使用（包括但不限于在各类网站、媒体上使用）。</p>
            <p><br></p>
            <p>
              6、用户通过广州创幻数码科技有限公司平台发布的信息或内容，并不代表广州创幻数码科技有限公司之意见及观点，也不意味着广州创幻数码科技有限公司赞同其观点或证实其内容的真实性。广州创幻数码科技有限公司有权删除网站内各类不符合法律或本协议规定的信息或内容，而保留不通知用户的权利。
            </p>
            <p><br></p>
            <p>
              7、用户通过广州创幻数码科技有限公司平台发布的信息、文字、图片等资料均由广州创幻数码科技有限公司用户提供，其真实性、准确性和合法性由信息发布人负责。广州创幻数码科技有限公司不提供任何保证，并不承担任何法律责任。如果以上资料侵犯了第三方的知识产权或其他权利，责任由信息发布者本人承担，广州创幻数码科技有限公司对此不承担责任。
            </p>
            <p><br></p>
            <p>
              8、除法律另有强制性规定外，未经广州创幻数码科技有限公司明确的特别书面许可，任何单位或个人不得以任何方式非法地全部或部分复制、传播、展示、镜像、上载、下载、转载、引用、链接、抓取或以其他方式使用本站的信息内容，否则，广州创幻数码科技有限公司有权追究其法律责任。
            </p>
            <p><br></p>
            <p>9、广州创幻数码科技有限公司平台以下内容不可任意转载：</p>
            <p><br></p>
            <p>1）本平台内发布的所有信息；</p>
            <p><br></p>
            <p>2）已作出不得转载或未经许可不得转载声明的内容；</p>
            <p><br></p>
            <p>3）本平台中特有的图形、标志、页面风格、编排方式、程序等；</p>
            <p><br></p>
            <p>4）本平台中必须具有特别授权或具有注册用户资格方可知晓的内容；</p>
            <p><br></p>
            <p>5）其他法律不允许或本平台认为不适合转载的内容。</p>
            <p><br></p>
            <p><br></p>
            <p>
              10、对于不当引用广州创幻数码科技有限公司网站内容而引起的纷争等或因纠纷等造成的任何损失，广州创幻数码科技有限公司不承担相关法律责任。对不遵守本声明的用户或其他违法、恶意使用广州创幻数码科技有限公司网站内容者，广州创幻数码科技有限公司保留追究其法律责任的权利。
            </p>
            <p><br></p>
            <p>九、法律适用、管辖与其他</p>
            <p><br></p>
            <p>1、本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国法律，如法律无相关规定的，则应参照通用国际商业惯例和（或）行业惯例。</p>
            <p><br></p>
            <p><br></p>
            <p>2、本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。</p>
            <p><br></p>
            <p>
              3、因本协议产生之争议、纠纷，应由广州创幻数码科技有限公司与您友好协商解决；协商不成的，应依照中华人民共和国法律予以处理，并以北京市朝阳区人民法院为第一审管辖法院，按照中华人民共和国法律，因级别管辖的规定，北京市朝阳区人民法院无权管辖的，以其上级法院作为第一审管辖法院。
            </p>
            <p><br></p>
            <p>
              为维护网民和相关权利人利益，广州创幻数码科技有限公司有限公司已经以公示的方式，为用户及相关权利人提供了丰富、便捷的线上线下投诉渠道（包括但不限于通过您的专属客服、广州创幻数码科技有限公司网站首页下方的违法信息举报电话或者通过广州创幻数码科技有限公司网站的帮助中心页面等渠道与广州创幻数码科技有限公司取得联系）。基于网络用户间的公平考量，同时也为了使网络用户的投诉能够及时得到处理，避免被当做垃圾邮件屏蔽，广州创幻数码科技有限公司有限公司拒绝接收和处理所有以电子邮件形式发送给本公司员工个人的网络侵权通知，更不承诺对发送给本公司员工个人的电子邮件侵权通知进行优先处理，特此告知。
            </p>
            <p><br style="white-space: normal;"></p>
            <p><br></p>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// import NavHeader from "./../components/NavHeader";
export default {
  name: "ag",
  components: {
    // NavHeader,
  },
  data() {
    return {
      href: "index.html",
    };
  },
  methods() {
    document.title = "广州创幻数码科技有限公司";
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.container {
 
}
</style>

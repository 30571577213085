<template>
  <div class="ac1">
    <div class="banner">
      <img src="/imgs/cp.jpg" alt="" />
    </div>


    <div class="container">
      <!-- 标题 -->
      <div class="at-title">
        <h2>产品中心</h2>
        <h4>Product Center</h4>
      </div>

      <!-- 循环项 -->
      <div class="at-item" v-for="(item, index) in this.myData" :key="index">
        <!-- 左侧图片 -->
        <div class="item-l">
          <img :src="item.img" alt="" />
        </div>
        <!-- 右侧信息 -->
        <div class="item-r">
          <!-- 标题 -->
          <div class="item-title">{{ item.title }}</div>
          <!-- 文本 -->
          <p>
            {{ item.jj }}
          </p>
          <!-- 信息层 -->
          <div class="item-info">
            <span @click="downloadFile(item.author, item.sub)">点击下载安卓版本</span>
            <!-- <span><i class="iconfont icon-dianzan"></i>{{ item.sub }}</span> -->
          </div>

          <span>开发单位：{{ item.pay }}</span>
        </div>
      </div>

      <!-- <div class="ct">
        <img src="/imgs/ct3.jpg" alt="">
      </div> -->
    </div>


    <modal title="请付款阅读" btnType="1" modalType="middle" :showModal="showModal" @submit="msub()"
      @cancel="showModal = false">
      <template v-slot:body>
        <img style="" src="/imgs/pay2.png" alt="" />
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "./../components/Modal.vue";
export default {
  name: "ac1",
  components: {
    Modal,
  },
  data() {
    return {
      href: "index.html",
      ac1: [],
      ac2: [],
      ac3: [],
      ac: [],
      myData: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.init1();
    document.title = "广州创幻数码科技有限公司";
  },
  methods: {
    init1() {
      this.axios.get("/mock/info.json").then((res) => {
        const data = res.data.splice(0, 3);
        // 咨询区块1
        this.myData = data;
      });
    },
    downloadFile(url, name) {
      // 创建一个不可见的 <a> 元素用于下载
      const link = document.createElement('a');

      // 动态设置 href 属性为传入的文件 URL
      link.href = url;

      // 动态设置 download 属性为传入的文件名
      link.download = name;

      // 自动触发点击事件，开始下载
      link.click();
    },
    // 跳转功能
    goDays(methodsWords, id) {
      this[methodsWords](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    // 收费逻辑
    gopay() {
      // console.log('1')
      alert("资讯每篇2元，点击确定购买");
      this.showModal = true;
      // this.$router.push({ path: "/login", query: { k: 1 } });

      // if (this.state == 0) {
      //   // this.$router.push("/pay");
      //   this.showModal = true;
      //   console.log(this.showModal);
      // }
      // // 未登录
      // if (this.state !== 0) {
      //   alert("资讯每篇3元，点击确定注册购买");
      //   this.$router.push({ path: "/login", query: { k: 1 } });
      //   return;
      // }
    },
    msub() {
      // alert("支付失败！");
      this.$message.error("支付失败!");
      this.showModal = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.ac1 {
  .banner {
    width: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .container {
    .ct {
      width: 100%;

      img {
        width: 100%;
      }
    }

    //   标题
    .at-title {
      width: 100%;
      height: 40px;
      border-left: 4px solid $colorZ;
      padding-left: 20px;
      box-sizing: border-box;
      margin-bottom: 20px;
      margin: 20px auto;

      h2 {
        font-size: 21px;
        color: #555;
      }

      h4 {
        font-size: 12px;
        color: #888;
      }
    }

    //   循环项
    .at-item {
      width: 100%;
      height: 220px;
      @include flex();
      box-sizing: border-box;
      transition: all 0.3s;
      margin: 28px auto;

      &:hover {
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
      }

      // 左侧
      .item-l {
        width: 30%;
        height: 100%;

        //   background: #c60023;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      // 右侧
      .item-r {
        width: 70%;
        height: 100%;
        //   background-color: #ff6600;
        padding: 20px;
        box-sizing: border-box;
        margin-left: 30px;

        //   标题
        .item-title {
          font-size: 16px;
          font-weight: bold;
          margin: 10px 0;
          transition: all 0.3s;
        }

        //   文本
        p {
          font-size: 14px;
          text-indent: 30px;
          color: #555;
          line-height: 25px;
          margin: 15px auto;
        }

        //   信息层
        .item-info {
          width: 100%;
          height: 40px;
          @include flex();

          span {
            font-size: 13px;
            color: #666;
            cursor: pointer;
            width: 150px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 30px;
            padding: 0 10px;
            box-sizing: border-box;
            transition: all .3s;

            &:hover {
              color: #fff;
              background-color: $colorZ;

            }

            i {
              margin-right: 10px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div>
    <!-- 导航栏 -->
    <!-- <nav-header :href="this.href"></nav-header> -->
    <div class="banner">
      <img src="/imgs/us.jpg" alt="">
    </div>
    <div class="container">
      <div class="article-main" style="width:1200px;">
        <div class="am-hd" style="margin: 25px 0;">
          <h2>关于我们</h2>

        </div>
        <div class="am-bd">
          <div class="article-words">

            <p>广州创幻数码科技有限公司成立于2015年06月19日，注册地位于广州市天河区金硕一路11号1206室</p>

            <p>
              经营范围包括计算机软硬件及辅助设备批发;数字文化创意技术装备销售;人工智能基础软件开发;人工智能应用软件开发;人工智能公共数据平台;人工智能基础资源与技术平台;人工智能理论与算法软件开发;人工智能公共服务平台技术咨询服务;组织文化艺术交流活动;数字文化创意内容应用服务;数字文化创意软件开发;数字技术服务;数字内容制作服务（不含出版发行）;网络技术服务;信息技术咨询服务;软件开发;技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广;自然科学研究和试验发展;婚姻介绍服务;婚庆礼仪服务;咨询策划服务;礼仪服务;影视美术道具置景服务;广播电视节目制作经营;第二类增值电信业务;
            </p>
          </div>

        </div>
      </div>


      <!-- <div class="ct">
        <img src="/imgs/ct4.jpg" alt="">
      </div> -->
    </div>
  </div>
</template>

<script>
// import NavHeader from "./../components/NavHeader";
export default {
  name: "ag",
  components: {
    // NavHeader,
  },
  data() {
    return {
      href: "index.html",
    };
  },
  methods() {
    document.title = "广州创幻数码科技有限公司";
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.banner {
  width: 100%;

  img {
    width: 100%;
  }
}

.container {
  .ct {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .article-words {
    p {
      line-height: 31px;
      font-size: 14px;
      margin: 25px 0;
    }
  }
}
</style>

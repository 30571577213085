<template>
  <div class="n-footer">
    <div class="container">
      <!-- 左侧信息 -->
      <div class="f-l">
        <p>电话： 020-38213802</p>
        <p>邮箱：15010507197@qq.com</p>
        <p>
          <a href="https://beian.miit.gov.cn/#/Integrated/index">网站备案：粤ICP备14048123号-2</a>
        </p>
        <p>联系地址：
          广州市天河区金硕一路11号1206室</p>

      </div>
      <!-- 右侧信息 -->
      <div class="f-r">
        <div class="f-top">
          <a href="/">网站首页</a> <span>|</span> <a href="/#/ac1">产品中心</a> <span>|</span>
          <a href="/#/ac3">关于我们</a>
          <span>|</span> <a href="/#/ac2">隐私协议</a> <span>|</span>
          <a href="/#/xy">用户协议</a>

        </div>
        <h2>广州创幻数码科技有限公司</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.n-footer {
  width: 100%;
  // height: 80px;
  margin-top: 40px;
  background: black;
  padding: 25px 0;
  box-sizing: border-box;

  //   background-color: #c60023;
  .container {
    height: 100%;
    @include flex();

    // 左侧
    .f-l {
      p {
        color: #fff;
        margin: 5px 0;

        a {
          color: #fff;
        }
      }
    }

    // 右侧
    .f-r {
      .f-top {
        margin-bottom: 15px;

        a {
          font-size: 12px;
          color: #fff;
        }

        span {
          font-size: 14px;
          margin: 0 3px;
          color: #fff;
        }
      }

      h2 {
        text-align: center;
        color: #fff;
      }
    }
  }
}
</style>
<template>
  <div>
    <!-- 上屏 -->
    <div class="m-top">
      <div class="l">
        <img src="" alt="" />
      </div>
      <div class="z">
        <img src="" alt="" />
      </div>
      <div class="r">
        <img src="" alt="" />
        <img src="" alt="" />
        <img src="" alt="" />
      </div>
    </div>

    <!-- 推荐店铺 -->
    <div class="m-us">
      <div class="m-us-title">
        <h2>推荐店铺</h2>
        <p>Recommended stores</p>
      </div>

      <div class="us-con">
        <!-- 循环项 -->
        <a href="" class="us-item">
          <img src="" alt="" />
          <p>卡通文创店</p>
        </a>
        <a href="" class="us-item">
          <img src="" alt="" />
          <p>卡通文创店</p>
        </a>
        <a href="" class="us-item">
          <img src="" alt="" />
          <p>卡通文创店</p>
        </a>
        <a href="" class="us-item">
          <img src="" alt="" />
          <p>卡通文创店</p>
        </a>
      </div>
    </div>

    <!-- 商品区块 -->
    <div class="m-con">
      <!-- 标题 -->
      <div class="m-con-top">
        <!-- 标题 -->
        <h2><span>中性</span>笔</h2>
        <!-- 更多 -->
        <a href="">更多></a>
      </div>
      <!-- 下层容器 -->
      <div class="con">
        <!-- 循环项 -->
        <a href="" class="con-item">
          <img src="" alt="" />
          <div class="item-info">
            <h4>铭实地产项目“印”系列策划方案</h4>
            <p>现价：￥15.00</p>
            <span>方案合集</span>
          </div>
        </a>
        <!-- 循环项 -->
        <a href="" class="con-item">
          <img src="" alt="" />
          <div class="item-info">
            <h4>铭实地产项目“印”系列策划方案</h4>
            <p>现价：￥15.00</p>
            <span>方案合集</span>
          </div>
        </a>
        <!-- 循环项 -->
        <a href="" class="con-item">
          <img src="" alt="" />
          <div class="item-info">
            <h4>铭实地产项目“印”系列策划方案</h4>
            <p>现价：￥15.00</p>
            <span>方案合集</span>
          </div>
        </a>
        <!-- 循环项 -->
        <a href="" class="con-item">
          <img src="" alt="" />
          <div class="item-info">
            <h4>铭实地产项目“印”系列策划方案</h4>
            <p>现价：￥15.00</p>
            <span>方案合集</span>
          </div>
        </a>
        <!-- 循环项 -->
        <a href="" class="con-item">
          <img src="" alt="" />
          <div class="item-info">
            <h4>铭实地产项目“印”系列策划方案</h4>
            <p>现价：￥15.00</p>
            <span>方案合集</span>
          </div>
        </a>
        <!-- 循环项 -->
        <a href="" class="con-item">
          <img src="" alt="" />
          <div class="item-info">
            <h4>铭实地产项目“印”系列策划方案</h4>
            <p>现价：￥15.00</p>
            <span>方案合集</span>
          </div>
        </a>
        <!-- 循环项 -->
        <a href="" class="con-item">
          <img src="" alt="" />
          <div class="item-info">
            <h4>铭实地产项目“印”系列策划方案</h4>
            <p>现价：￥15.00</p>
            <span>方案合集</span>
          </div>
        </a>
        <!-- 循环项 -->
        <a href="" class="con-item">
          <img src="" alt="" />
          <div class="item-info">
            <h4>铭实地产项目“印”系列策划方案</h4>
            <p>现价：￥15.00</p>
            <span>方案合集</span>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ac1",
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.m-top {
  width: 1200px;
  height: 500px;
  margin: 30px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .l {
    width: 292px;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background-color: pink;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        box-shadow: 2px 2px 5px #ccc;
      }
    }
  }
  .z {
    flex: 1;
    height: 100%;
    margin: 0 20px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background-color: #ff6600;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        box-shadow: 2px 2px 5px #ccc;
      }
    }
  }
  .r {
    width: 345px;
    height: 100%;

    img {
      width: 100%;
      height: 154px;
      background-color: #fff;
      background-color: red;
      margin-bottom: 20px;
      border-radius: 10px;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        box-shadow: 2px 2px 5px #ccc;
      }
    }
  }
}

.m-con {
  width: 1200px;
  margin: 40px auto;
  // 标题
  .m-con-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 10px;
    box-sizing: border-box;
    h2 {
      font-size: 26px;
      font-weight: bold;
      span {
        color: #c60023;
        margin-right: 2px;
      }
    }

    a {
      font-size: 12px;
      color: #333;
      text-decoration: none;
      font-weight: bold;
      transition: all 0.3s;
      &:hover {
        color: #c60023;
      }
    }
  }

  // 下层容器
  .con {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    // 循环项
    .con-item {
      width: 290px;
      height: 340px;
      margin: 15px 5px;
      transition: all 0.3s;
      cursor: pointer;
      &:hover {
        box-shadow: 0px 20px 21px -9px #686868;
      }
      img {
        width: 100%;
        height: 194px;
        border-radius: 8px;
        background-color: red;
      }
      .item-info {
        width: 100%;
        height: 146px;
        background-color: rgb(240, 238, 238);
        padding: 10px;
        box-sizing: border-box;
        cursor: pointer;
        h4 {
          font-size: 16px;
          font-weight: bold;
          color: #333;
        }

        p {
          font-size: 14px;
          color: #c60023;
          margin: 15px 0;
          line-height: 50px;
          border-bottom: 1px solid #ebebeb;
        }
        span {
          font-size: 12px;
          color: #888;
        }
      }
    }
  }
}

.m-us {
  width: 1200px;
  margin: 40px auto;
  .m-us-title {
    text-align: center;
    cursor: pointer;
    h2 {
      font-size: 28px;
      color: #48d1d1;
      font-weight: normal;
    }
    p {
      margin: 10px 0;
      color: #888;
      font-size: 21px;
    }
  }

  .us-con {
    width: 100%;
    display: flex;
    align-items: center;

    .us-item {
      width: 282px;
      background: rgb(249, 245, 245);
      text-align: center;
      padding: 15px 0;
      box-sizing: border-box;
      cursor: pointer;
      margin: 0 15px;
      transition: all 0.3s;
      border-radius: 15px;
      &:hover {
        transform: translateY(-10px);
      }
      img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-color: #c60023;
        margin: 30px auto;
      }

      p {
        font-size: 16px;
        font-weight: bold;
        color: #333;
      }
    }
  }
}
</style>

<template>
  <div class="index">
    <!-- 资讯区块1 -->
    <!-- <div class="a1">
      <img src="/imgs/banner.jpg" alt="">
    </div> -->

    <div class="container">


      <!-- <div class="ct">
        <img src="/imgs/ct2.jpg" alt="">
      </div> -->


      <!-- 资讯区块2 -->
      <div class="a2">
        <!-- 标题 -->
        <div class="a2-title">
          <span>软件产品列表</span>
          <p>开启智能交互时代</p>
        </div>

        <!-- 容器 -->
        <div class="a2-con">
          <!-- 循环项 -->
          <div class="a2-item" v-for="(item, index) in this.ac2" :key="index">
            <img :src="item.img" alt="" />
            <h2>{{ item.title }}</h2>
            <h6 style="margin-top: 15px;">开发单位：{{ item.pay }}</h6>
            <p>{{ item.jj }}</p>
            <h4  @click="downloadFile(item.author, item.sub)">下载安卓版本</h4>

          </div>
        </div>
      </div>


      <!-- <div class="ct">
        <img src="/imgs/ct1.jpg" alt="">
      </div> -->

    </div>



    <!-- 弹框 -->
    <modal title="请付款阅读" btnType="1" modalType="middle" :showModal="showModal" @submit="msub()"
      @cancel="showModal = false">
      <template v-slot:body>
        <img style="" src="/imgs/pay2.png" alt="" />
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "./../components/Modal.vue";
export default {
  components: {
    Modal,
    // info,
  },
  data() {
    return {
      href: "index.html",
      ac1: [],
      ac2: [],
      ac3: [],
      ac: [],
      ac4: [],
      myData: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.init1();
    document.title = "广州创幻数码科技有限公司";

  },
  methods: {
    init1() {
      this.axios.get("/mock/ac1.json").then((res) => {
        const data = res.data;
        console.log(data)

        // 咨询区块1
        this.myData = data;
        this.ac4 = data.slice(0, 1);
        this.ac1 = data.slice(1, 9);

      });

      this.axios.get("/mock/info.json").then((res) => {
        const data = res.data;

        this.ac2 = data.slice(0, 3);

      });
    },
    // 跳转功能
    goDays(methodsWords, id) {
      this[methodsWords](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    // 收费逻辑
    gopay() {
      // console.log('1')
      alert("资讯每篇2元，点击确定购买");
      this.showModal = true;
      // this.$router.push({ path: "/login", query: { k: 1 } });
      // if (this.state == 0) {
      //   // this.$router.push("/pay");
      //   this.showModal = true;
      //   console.log(this.showModal);
      // }
      // // 未登录
      // if (this.state !== 0) {
      //   alert("资讯每篇3元，点击确定注册购买");
      //   this.$router.push({ path: "/login", query: { k: 1 } });
      //   return;
      // }
    },
    msub() {
      // alert("支付失败！");
      this.$message.error("支付失败!");
      this.showModal = false;
    },

    downloadFile(url, name) {
      // 创建一个不可见的 <a> 元素用于下载
      const link = document.createElement('a');

      // 动态设置 href 属性为传入的文件 URL
      link.href = url;

      // 动态设置 download 属性为传入的文件名
      link.download = name;

      // 自动触发点击事件，开始下载
      link.click();
    }

  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.index {
  width: 100%;
  // height: 800px;
  position: relative;
  background-color: #fff;
  box-sizing: border-box;

  // 资讯区块1
  .a1 {
    width: 100%;
    @include flex();
    margin-bottom: 30px;

    img {
      width: 100%;
      height: 100%;
      background: pink;
      // cursor: pointer;
    }
  }


  .container {
    height: 100%;



    // 资讯区块2
    .a2 {
      margin: 35px 0;

      // 标题
      .a2-title {
        width: 100%;
        text-align: center;
        color: #414141;
        font-size: 36px;

        p {
          font-size: 18px;
          color: #414141;
          margin: 15px 0;
        }
      }

      //   容器
      .a2-con {
        width: 100%;
        // height: 700px;
        // background: pink;
        margin-bottom: 130px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        // 循环项
        .a2-item {
          width: 340px;
          background: #fff;
          padding: 0 15px;
          box-sizing: border-box;
          margin: 30px 30px;
          margin-left: 0;
          cursor: pointer;

          //   图片
          img {
            width: 100%;
            // height: 230px;
          }

          h2 {
            font-size: 18px;
            font-weight: 400;
            margin-top: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 260px;
          }

          p {
            width: 100%;
            background-color: #fff;
            font-size: 14px;
            color: #888;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            margin: 20px 0;
          }

          h4 {
            text-align: right;
            font-size: 12px;
            color: #c60023;
          }
        }
      }
    }

    .ct {
      width: 100%;

      img {
        width: 100%;
      }
    }
  }
}
</style>